<template>
  <div class="view pa24">
    <div style="font-size: 26px;font-weight: bold;margin-bottom: 30px;">设置费率</div>
    <el-form style="width: 50%;" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
      <el-form-item label="费率" prop="rate">
        <el-input v-model="ruleForm.rate" placeholder="请输入" clearable>
          <template #append>%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="单笔费用" prop="fee">
        <el-input v-model="ruleForm.fee" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="最低限额" prop="min">
        <el-input v-model="ruleForm.min" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="最高限额" prop="max">
        <el-input v-model="ruleForm.max" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="小额每日限额" prop="min_day_limit">
        <el-input v-model="ruleForm.min_day_limit" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="小额最大值" prop="min_maxlimit">
        <el-input v-model="ruleForm.min_maxlimit" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="区间费率开关" prop="qujian_open">
        <el-switch
            v-model="ruleForm.qujian_open"
            active-text="开启"
            inactive-text="关闭">
        </el-switch>
      </el-form-item>
      <el-button type="primary" :disabled="!ruleForm.qujian_open" style="margin-bottom: 20px" @click="add">+ 添加
      </el-button>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="金额范围" align="center" width="400">
          <template #default="scope">
            <div style="display: flex">
              <el-input clearable v-model="scope.row.min" placeholder="最小金额"></el-input>
              <div style="align-self: center">-</div>
              <el-input clearable v-model="scope.row.max" placeholder="最大金额"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="费率" align="center">
          <template #default="scope">
            <el-input clearable v-model="scope.row.rate"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="单笔费用" align="center">
          <template #default="scope">
            <el-input clearable v-model="scope.row.fee"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template #default="scope">
            <el-button type="danger" plain @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form-item label="排除商户" prop="extra_mch" style="margin-top: 20px">
        <el-input v-model="ruleForm.extra_mch" placeholder="商户账号以英文逗号隔开" clearable></el-input>
      </el-form-item>
      <el-form-item label="谷歌验证码" prop="google">
        <el-input v-model="ruleForm.google" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="determine">提 交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex';
import {
  getAgentDfrate,
  setAgentDfRate
} from "@/api/settlement";

let ac;
export default {
  name: 'setFl',
  data() {
    return {
      options: [],
      ruleForm: {
        rate: "",
        fee: "",
        min: "",
        max: "",
        min_day_limit: "",
        min_maxlimit: "",
        qujian_open: false,
        extra_mch:'',
        google:''
      },
      rules: {
        rate: [
          {required: true, message: '请输入费率'}
        ],
        fee: [
          {required: true, message: '请输入单笔费用'}
        ],
        google: [
          {required: true, message: '请输入谷歌验证码'}
        ],
      },
      tableData: [],
    };
  },
  mounted() {
    this.getFl()
  },

  methods: {
    getFl(){
      getAgentDfrate()
          .then((res) => {
            this.ruleForm.rate = res.data.rate
            this.ruleForm.fee = res.data.fee
            this.ruleForm.min = res.data.min
            this.ruleForm.max = res.data.max
            this.ruleForm.min_day_limit = res.data.min_day_limit
            this.ruleForm.min_maxlimit = res.data.min_maxlimit
            this.ruleForm.qujian_open = res.data.qujian_open == 0 ? false : true
            this.tableData = res.data.qujian_config?res.data.qujian_config:[]
            this.ruleForm.extra_mch = res.data.extra_mch
          })
    },
    add() {
      this.tableData.push({})
      for (let i in this.tableData) {
        this.tableData[i]['index'] = Number(i) + 1
      }
    },
    del(row) {
      for (let i in this.tableData) {
        if (this.tableData[i].index == row.index) {
          this.tableData.splice(i, 1)
        }
      }
    },
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ajax = {
            rate: this.ruleForm.rate,
            fee: this.ruleForm.fee,
            min: this.ruleForm.min,
            max: this.ruleForm.max,
            min_day_limit: this.ruleForm.min_day_limit,
            min_maxlimit: this.ruleForm.min_maxlimit,
            qujian_open: this.ruleForm.qujian_open ? 1 : 0,
            // user_id: this.id,
            qujian_config:this.tableData,
            extra_mch:this.ruleForm.extra_mch,
            google:this.ruleForm.google,
          }
          setAgentDfRate(ajax)
              .then((res) => {
                if (res.code === 1) {
                  this.ruleForm.google = ''
                  this.$message.success('保存成功');
                  this.getFl()
                } else {
                  this.$message.error(res.msg);
                  return;
                }
              })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  //line-height: 175px;
  text-align: center;
}

/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}

/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}

.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}

.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}

/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>